import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, DestroyRef, inject, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { LocalStorageEnum, ModalEnum } from '@enums';
import { environment } from '@env';
import { GeoService } from '@service';
import { forkJoin } from 'rxjs';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false,
})
export class AppRootComponent implements OnInit {
  protected readonly ModalEnum = ModalEnum;

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private geoService: GeoService,
    private ls: LocalStorageService,
    private http: HttpClient,
  ) {
    if (isPlatformBrowser(platformId)) {
      this.ls.initLocalStorage();
    }

    if (!environment.production && !this.ls.getItem(LocalStorageEnum.CURRENT_LANGUAGES_STATE)) {
      this.checkMissingLangVariables();
    }

    if (this.ls.getItem(LocalStorageEnum.GeoUserInfo)) {
      return;
    }

    if (!environment.production) {
      this.geoService.getUserInfo().subscribe((res) => {
        this.ls.setItem(LocalStorageEnum.GeoUserInfo, this.mappingHeadersCF(res.headers));
      });
    }

    this.geoService.getUserInfo().subscribe((res) => {
      this.ls.setItem(LocalStorageEnum.GeoUserInfo, this.mappingHeadersCF(res.headers));
    });
  }

  ngOnInit() {}

  private mappingHeadersCF(h: HttpHeaders): any {
    const headers = h.keys().reduce((acc, key) => {
      acc[key] = h.get(key);
      return acc;
    }, {});

    return {
      city: headers['x-user-city'] || '',
      country: headers['x-user-country-code'] || '',
      ip: headers['x-real-ip'] || '',
      loc: headers['x-user-geo'] || '',
      region: headers['x-user-region'] || '',
      postal: headers['x-user-postal'] || '',
      timezone: headers['x-user-timezone'] || '',
    };
  }

  private checkMissingLangVariables(): void {
    // const fileNames = ['en', 'de', 'cz', 'it', 'pl', 'sk', 'ua', 'ru'];
    const fileNames = ['en', 'sk', 'de', 'ua', 'ru'];
    const requests = fileNames.map((file) => this.http.get(`assets/i18n/${file}.json`));

    const fillMissingKeys = (source: any, target: any): any => {
      const result = {};
      for (const key in source) {
        if (source.hasOwnProperty(key)) {
          if (typeof source[key] === 'object' && !Array.isArray(source[key])) {
            const subResult = fillMissingKeys(source[key], target[key] || {});
            if (Object.keys(subResult).length > 0) {
              result[key] = subResult;
            }
          } else if (!target || !target.hasOwnProperty(key)) {
            result[key] = source[key];
          }
        }
      }
      return result;
    };

    forkJoin(requests)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((responses) => {
        const res = {};
        fileNames.forEach((file, index) => {
          res[file] = fillMissingKeys(responses[0], responses[index]);
        });
        this.ls.setItem(LocalStorageEnum.CURRENT_LANGUAGES_STATE, res);
        console.log('>>> if something is missing - its in here: ', res);
      });
  }

  protected readonly environment = environment;
}
