import { Component, OnInit } from '@angular/core';
import { environment } from '@env';
import { getCurrentDate } from '@helpers';

@Component({
  selector: 'app-base-root',
  templateUrl: './base-root.component.html',
  standalone: false,
})
export class BaseRootComponent implements OnInit {
  public showBaseAuth: boolean;

  ngOnInit() {
    this.showBaseAuth = environment.useBaseAuth && sessionStorage.getItem('baseAuthDone') !== getCurrentDate();
  }
}
